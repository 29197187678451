@import '../../vars';

.rapportChapt {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &__card {
        margin-top: 5rem;
        padding: 0 5rem;

        &__title {
            text-align: center;
            font-size: 2rem;
        }

        &__text{
            font-size: 1.2rem;
            text-align: justify;

            &__big {
                font-weight: 600;
                font-size: 1.2rem;
            }
        }
    }

    &__conseils {
        display: flex;
        width: 100%;
        margin-bottom: 1vh;

        &__container {
            display: grid;
            grid-template-columns:  0.15fr 2fr 1fr 1.5fr 0.15fr;
            gap: 3rem;
            align-items: center;
            margin-top: 0.5vh;
        }

        &__title {
            font-size: 2rem;
            margin-bottom: 1.5vh;
        }
        &__part{
            &__text {
                flex: 1;
                display: flex;
                text-align: justify;
                margin-top: 1.5rem;
                justify-content: center;
            }

            &__title {
                flex: 1;
                margin-top: 2rem;
                font-weight: 700;
                text-align: center;
            }
                
        }
    }

    &__recap__container {
        width: 85%;
        align-self: center;
        margin: 1rem 0rem;
    }

    &__score{
        font-weight: 800;
        font-size: 1.5rem;
    }
}

.recap {
    &__table {
        width: 100%;
        

        &__arrow {
            background-color: white;
            width: 50px; height: 25px;
            clip-path: polygon(50% 70%, 100% 0, 100% 30%, 50% 100%, 0 30%, 0 0);
            align-self: center;
            transition: 0.5s;

            &__container {
                cursor:pointer;
                position: absolute;
                transform: translate(-10%, 15px);
                top: 0; right: 0;
            }

            &__reverse {
                background-color: white;
                width: 50px; height: 25px;
                clip-path: polygon(50% 70%, 100% 0, 100% 30%, 50% 100%, 0 30%, 0 0);
                transform: scaleY(-1) scaleX(-1);
                align-self: center;
                transition: 0.5s;
            }
        }

        &__row {
            display: grid;
            width: 100%;
            grid-template-columns: 4fr 1fr 1fr;
            padding: 1rem 2rem;
            position: relative;
            border: solid 1px black;
            border-top: none;
            border-radius: 1rem;

            &__title{
                background-color: $grey;
                color: white;
            }
            
            &__pair {
                background-color: $middleGrey;
            }

            &__impair {
                background-color: white;
            }
        }
        
        &__column {
            &__title{
                align-items: center;
                justify-self: center;
                display: flex;
                font-weight: 800;
                font-size: 1.2rem;
                height: 2.5rem;
                
            }

            &__total {
                font-weight: 800;
                text-transform: uppercase;
            }

            &__note {
                text-align: center;
                font-weight: 800;
                

                &__green {
                    color: green;
                }

                &__red {
                    color: $darkOrange;
                }
            }
        }

        &__title {
            text-align: center;
            font-size: 2.5rem;
            font-weight: 800;
            margin-bottom: 1rem;

        }
    }
}
