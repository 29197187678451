@import '../../vars';

.footer {
    &__mobile {
        position: fixed;
        width: 100%;
        height: 5vh;
        background-color: $darkBlue;
        bottom: 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr); 
        color: white;
        align-items: center;
        justify-content: center;
        
        &__uranie {
            margin: 0 0.25rem;
            height: 4.5vh;
        }

        &__text {
            margin: 0;
            font-size: 0.75rem;
            text-align: center;
        }
    }

    
    
}