@import '../../vars';

.themes {
    width: 100%;
    height: 100%;
    display: grid;
    overflow: auto;
    &__intro {
        width: 90%;
        justify-self: center;
        text-align: center;
        margin-top: 5vh;
    }

    &__button {
        background-color: $darkBlue;
        border-radius: 0.5rem;
        padding: 0.25rem 0.5rem;
        color: white;
        font-size: 1.5rem;
        margin-bottom: 2.5rem;
        width: 60%;
        justify-self: center;
        text-align: center;
        
    }

    &__choice {
        display: flex;
        width: 100%;
        justify-self: center;
        justify-content: space-between;
        background-color: $darkOrange;
        align-items: center;
        color: white;
        border-radius: 0.5rem 0.5rem 0 0;

        &__title {
            margin-left: 1rem;
        }
    }

    &__container {
        display: grid;
        width: 90%;
        justify-self: center;
        border: solid 1px black;
        margin: 1rem 0;
        border-radius: 0.5rem 0.5rem 0 0;
    }

    &__description {
        background-color: white;
        padding: 1rem;
        text-align: justify;
        max-height: 20vh;
        overflow: auto;
    }
}