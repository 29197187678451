@import '../../vars';

.pdf {
    &__button{
        z-index:10;
        cursor: pointer;
        position: absolute;
        top:8px; right:10%;
        display: flex;
        justify-content: center;
        align-items: center;
        height:40px;
        color: white;
        margin-top: 5px;
        background-color: $darkOrange;
        border-radius: 0.5rem;
        text-align: center;
        padding: 0 1rem;
        transition: 0.5s;

        &:hover{
            background: $orange;
            transition: 0.5s;
        }
        
    }
    &__viewer {
        width: 100%;
        height: 95%;
    }
}