@import '../../vars';

.adjustement{
    height: 2vh;
}
.rapportWeb {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    right: 5vw;

    &__verifUser {
        align-self: center;
        position: absolute;
        top: 0; left: 0;
        width: 100vw; height: 100%;;
        display: flex;
        align-items: center;
        justify-content: space-around;

        &__content {
            height: 30%;
            display: flex;
            flex-direction:column;
            align-items: center;
        justify-content: space-around;
        }

    }

    &__button {
        align-self: flex-start;
        background-color: $darkBlue;
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        color: white;
        font-weight: 600;
        transition: 0.5s;
        font-size: clamp(1.2rem, 1.5vh, 1.5vh);
        cursor: pointer;
        z-index:2;
        &:hover {
            background-color: $blue;
            transition: 0.5s;
        }

        &__container {
            display: flex;
            margin-top: 2rem;
            width: 80%;
            align-self: center;
            justify-content: space-around
        }
    }

    &__noScroll {
        overflow: hidden;
    }
    

    &__row{
        margin-top: 5vh;
        display: flex;
        width: 95%;
        height: 100%;
        align-self: center;
 
        &__2{
            width: 95%;
            display: grid;
            grid-template-columns: repeat(2,1fr);
            gap: clamp(2rem, 3vw, 5vw);
            align-self: center;
            margin-bottom: clamp(1rem, 2vh, 2vh)
            
        }
    }

    &__return {
        &__button {
            position: absolute;
            padding: 0.5rem 1rem;
            background-color: $darkOrange;
            color: white;
            border-radius: 0.5rem;
            top: 1rem; left: 7.5%;
            transform: translate(-50%);
            transition: 0.5s;
            cursor: pointer;
            &:hover {
                background-color: $orange;
                transition: 0.5s;
            }
        }
    }

    &__indicator {
        &__title {
            text-align: center;
            font-size: clamp(2rem, 5vh, 5vh);
            color: $darkBlue;
            font-weight: 600;
            margin: 0;
        }
    }

    &__graph {
        width: 100%; height: 100%;
    }

    &__modal{
        position: fixed;
        width: 100vw;
        height:105vh;
        top: 0; left: 0;
        background: rgba(0,0,0,0.75);
        z-index: 10;
    }

    &__part {
        width: 100%; height: 100%;
        flex:1;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        grid-row: 1 / span 2;

        &__row2 {
            margin: 0; 
        }

    }

    &__recap {
        &__container {
            width: 100%;
        }
    }

    &__slides {
        width: 60%;
        height: 100%;
        display: flex;
        flex-direction: column;

        &__title {
            text-align: center;
            font-size: 3vh;
        }
    }

    &__switch {
        position: absolute;
        top:20%;
        text-align: center;
        left: 50%;
        transform: translateX(-50%);
    }

    &__text {
        font-size: clamp(1rem, 1.5vh, 1.5vh)
    }

    &__title {
        font-size: clamp(2rem, 3vh, 3vh);
        text-align: center;
        font-weight: 600;
        margin-bottom: clamp(1rem, 2vh, 2vh)
    }
}

.error {
    border: red solid 2px;
}