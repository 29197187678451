@import '../../../vars';


.mobile {
    &__modal { 
    position: absolute;
    top: 10%; left: 50%;
    transform: translateX(-50%);
    background-color: white;
    padding: 1rem 2rem;
    border-radius: 1rem;
    display:grid;
    transition: 0.5s;

    &__hidden{
        transform: translate(-50%, -50%);
        transition: 0.5s;
        z-index: -1;
    }
    
    &__background {
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        background-color: rgba(0,0,0,0.8);
        z-index: 10;
        transition: 0.5s;
        opacity: 1;

        &__hidden{
            display: none;
        }
    }

    &__button {
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        background-color: $darkOrange;
        color: white;
        border: none;
        justify-self: flex-end;
        width: 100%;
        cursor: pointer;
        transition: 0.5s;

        &:hover {
            background-color: $orange;
            transition: 0.5s;
        }
    }

    &__checkbox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }

    &__label {
        font-size: clamp(1rem, 1.2vh, 1.2vh)
    }

    &__title {
        text-align: center;
        font-size: clamp(1.5rem, 1.5vh, 1.5vh);
    }
}
}