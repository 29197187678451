@import '../../vars';

.rapport {
    width: 100%;
    height: 100%;
    
    &__contact {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-bottom: 4rem;;
        &__infos {
            text-align: center;
            margin-top: 2rem;
            font-size: clamp(1.5rem, 1.5vh, 1.5vh)
        }

        &__text{
            display: grid;
            padding: 1rem 2rem;
            position: relative;
            border-top: solid 1px rgb(11,11,11);
            border-radius: 1rem;
            width: 70%;
            justify-self: center;
            box-shadow: -1px 5px 10px 2px rgba(11,11,11,0.5);
            background-color: white;
            margin-top: 3rem;
            text-align: justify;
        }

        &__graph{
            display: none;
        }

        &__question {
            font-style: italic;
            font-size: 0.9rem;
            margin: 2rem 0;
        }
    }

    &__loader {
        top:50%;
        left: 50%;
        width: 80%; height: 80%;
        align-self: center;
        justify-self: center;

        &__container{
            position: absolute;
            width: 100%;
            height: 100%;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__pdf {
        &__button {
        background-color: $darkOrange;
        text-align: center;
        align-self: center;
        border: none;
        border-radius: 0.5rem;
        color: white;
        padding: 0.25rem 0.5rem;
        margin-top: 1.5rem;

        &__container {
            top: 0; left: 1rem;
            z-index: 10;
            display: flex;
            flex-direction: column;
        }
        }
    }
}