@import '../../vars';

.background {
    background-image: url(../../assets/img/valliance/bas_de_page.png);
    width: 100vw; height: 100vh;
    background-size: 200%;
    background-repeat: no-repeat;
    background-position: bottom;
    overflow: auto;
    position: absolute;
}

.mobileApp{
    position: absolute;
    top: 0;left: 0;
    width: 100vw; height: 95vh;
    
    &__language{
        background-color: $darkBlue;
        width: 20vw;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        border-radius: 15px;
        padding: 0.25rem 0.5rem; 
        margin: 1rem 0 0 1rem;
        position: relative;
        transition: 0.5s;

        &__open {
            background-color: $darkOrange;
            transition: 0.5s;
        }
        
        &__ico{
            height: 3vh;
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
        }

        &__langues {
            display: flex;
            flex-direction: column;
            transition: 0.5s;

            &__open{
                transition: 0.5s;
            }
        }

        &__text {
            color: white;
            margin: 0;
            font-size: clamp(10px, 2vh, 2vh)
        }
    }
}