@import '../../vars';

.modal {
    &__background {
        z-index: 1999;
        position: absolute;
        top: 0; left: 0;
        background-color: rgba(0,0,0, 0.8);
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
    }

    &__container {
        width: 25vw;
        height: 25vh;
        padding: 1rem 2rem;
        border: 2px solid $darkGrey;
        border-radius: 1.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: white;
        margin-top: 2.5rem;
    }

    &__text {
        font-size: 1.2rem;
        text-align: center;
        font-weight: 600;
    }

    &__button {
        padding: 0.5rem 1rem;
        background-color: $darkOrange;
        border-radius: 1rem;
        text-align: center;

        &:hover{
            background-color: $orange;
        }
        &__container {
            display: flex;
            margin-top: 3rem;
            justify-content: space-around;
            width: 100%;
        }
    }

}