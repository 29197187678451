@import '../../vars';


.modal { 
    position: absolute;
    top: 10%; left: 50%;
    transform: translateX(-50%);
    background-color: white;
    padding: 1rem 2rem;
    border-radius: 1rem;
    width: 20%;
    display:grid;
    transition: 0.5s;

    &__hidden{
        transform: translate(-50%, -50%);
        transition: 0.5s;
        z-index: -1;
    }
    
    &__background {
        width: 100vw;
        height: 100vh;
        position: absolute;
        background-color: rgba(0,0,0,0.8);
        z-index: 10;
        transition: 0.5s;
        opacity: 1;

        &__hidden{
            opacity: 0;
            transition: 0.5s;
            z-index: -1;
        }
    }

    &__button {
        padding: 0.5rem 1rem;
        border-radius: 0.5rem;
        background-color: $darkOrange;
        color: white;
        border: none;
        justify-self: flex-end;
        width: 20%;
        cursor: pointer;
        transition: 0.5s;

        &:hover {
            background-color: $orange;
            transition: 0.5s;
        }
    }

    &__checkbox {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    &__label {
        font-size: clamp(1.2rem, 1.2vh, 1.2vh)
    }

    &__title {
        text-align: center;
        font-size: clamp(1.5rem, 1.5vh, 1.5vh);
    }

    &__pin {
        width: 25vw;
        height: 25vh;
        padding: 1rem 2rem;
        border: 2px solid $darkGrey;
        border-radius: 1.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: white;
        margin-top: 2.5rem;
        position: absolute;
        top: 5rem; left: 50%;
        transform: translate(-50%);
        z-index: 100;

        &__background {
            position: absolute;
            width: 100vw;
            height: 100vh;
            background-color: rgba($darkGrey, 0.8);
            z-index: 99;
        }

        &__buttonClose {
            width: 25px; height: 25px;
            position: absolute;
            top: 0.5rem; right: 0.5rem;
            clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
            background-color: $darkOrange;
            cursor: pointer;
        }

        &field{
            width: 5%;
            height: 5vh;
            margin-left: 0.5rem;
            margin-right: 0.5rem;
            border-radius: 0.5rem;
            font-size: 5vh;
            text-align: center;

            &__container {
                display: flex;
                justify-content: center; 
            }

            &__reject{
                border-color: red;
                color: red;
            }
        }

    }
}