@import './vars';

a {
    text-decoration: none;
}

body {
    font-family: "Lato",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
    margin: 0;
}

.animation {
    transition: 3s;
}

.page__principale {
    width: 100%;
    height: 95%;
    overflow-y: auto;
    padding-left: 15vw;
}

.loader {
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);
    width: 10vw;
}

.separator {
    width: 95%;
    align-self: center;
    height: 2px;
    background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba($darkGrey,1) 50%, rgba(0,0,0,0) 100%);
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.grid {
    display: grid;
}
