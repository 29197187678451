@import '../../vars';

.mobileConnexion {
    width: 100%;
    margin-top: 5%;
    height: auto;
    display: flex;

    &__button {
        margin-top: 4vh;
        padding: 1rem 2rem;
        border-radius: 1rem;
        font-size: clamp(12px, 1.5vh, 1.5vh);
        border: none;
        height: 8vh;
        color: white;
        font-weight: 800;
        background: $darkBlue;
        transition: 0.5s;
        cursor: pointer;

        &__close {
            height: 8vh;
            background-color: transparent;
            border: none;
            margin-top: 4vh;
            font-weight: 800;
            transition: 0.5s;
            color: white;
        }
}

    &__container {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
    }
    &__input {
        width: 75%;
        border-radius: 1rem;
        padding: 0.5rem 1rem;
        font-size: 1.2rem;
    }
    &__loader {
        top:0;
        left: 0;
        width: 85%; height: 85%;
        position: relative;

        &__container{
            position: absolute;
            width: 100vw;
            height: 90%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    &__title {
        text-align: center;
        background-color: $darkBlue;
        width: 75%;
        padding: 0.5rem 1rem;
        color: white;
        clip-path: polygon(5% 0%, 100% 0%, 95% 100%, 0% 100%);
        font-size: clamp(10px, 2vh, 2vh);
        font-weight: 700;
        margin-bottom: 2rem;
    }

    &__text {
        width: 75%;
        font-size: 0.95rem;
        margin-bottom: 2rem;
    }


}