@import '../../vars';

@keyframes slide-menu {
    0% {
        opacity: 0;
        transform: translateX(100vh);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
  }

.mobile {
    &__menu {
    width: 100%; height: 100%;
    display: grid;
    padding-left: 0.75rem;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    align-items: center;
    color: white;
    transition: 0.75s;
    opacity: 1;
    background-color: rgba($darkGrey, 0.9);
    position: absolute;
    left: 0; top: 0;
    z-index: 10;
    overflow: auto;
    animation: slide-menu 1s cubic-bezier(0.075, 0.820, 0.165, 1.000) both;
    padding-bottom: 2.5rem;
    

    &__burger {
        &__container{
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: clamp(36px, 2.5vh, 2.5vh);
            height: clamp(30px, 2.5vh, 2.5vh);
            z-index:11;
        }

        &__stick{
            width: 100%;
            height: 15%;
            background-color: $darkBlue;
            transition: 0.5s;
            z-index: 1;
            border-radius: 1rem;

            &__left {
                transform: translateY(-350%) rotate(405deg);
                background-color: $darkOrange;
            }

            &__right{
                transform: translateY(75%) rotate(-405deg);
                transition: 0.5s;
                background-color: $darkOrange;
            }

            &__none {
                opacity: 0;
                transform: rotate(180deg);
                transition: 0.5s;
            }
        }
    }

    &__buttonSelect {
        width: 3rem; height: 3rem;
        background-color: $darkOrange;
        clip-path: polygon(40% 0%, 40% 20%, 100% 20%, 100% 80%, 40% 80%, 40% 100%, 0% 50%);
    }
    &__close {
        transition: 0.75s;
        position: absolute;
        transform: translateX(100vw);
        opacity: 0;
        
    }
    &__container{
        position: absolute;
        overflow: hidden;
        top: 0; right: 0;
        width: 100vw; height: 100vh;
        z-index: 1;

        &__close {
            z-index: -100
        }
        
    }

    &__undertitle {
        color: white;
        margin-top: 2.5rem;;
        font-weight: 500;
        max-width: 90vw;
    }
    &__title{
        margin-left: 1rem;
        color: white;
        font-weight: 600;
    }
}

    
}
