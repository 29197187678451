@import '../../vars';

.responses{
    &__input{
        border-radius: 0.5rem;
        padding: 0.3rem 0.75rem;
        border: 1px solid black;
    }

    &__textarea {
        border-radius: 0.5rem;
        padding: 0.3rem 0.75rem;
        border: 1px solid black;
        resize: vertical;
        min-height: 3rem;
        font-family: Arial, Helvetica, sans-serif;
    }

    &__button {
        background-color: $orange;
        border: none;
        border-radius: 0.5rem;
        color: $grey;
        padding: 0.25rem 0.5rem;
        width: 45%;
        margin-top: 0.5rem;
        transition: 0.5s;
        border: solid 2px transparent;

        &__selected {
            background-color: $darkBlue;
            border: solid 2px black;
            color: white;
            transition: 0.5s;
        }
        


        &__container{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }
    }
}