.gaug{
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    height: 50vh;

    &__obj {
        align-self: center;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}