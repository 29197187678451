@import '../../vars';

.menuChoice{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin-top: 5rem;
    padding-bottom: 5rem;

    &__button {
        padding: 0.25rem 0.5rem;
        border-radius: 1rem;
        background-color: $darkOrange;
        width: auto;
        color: white;
        text-align: center;
        font-size: 1.75rem;
        cursor: pointer;
        transition: 0.5s;
        &:hover {
            background-color: $orange;
        }
        &__selected{
            background-color: $darkBlue;
            transition: 0.5s;

            &:hover {
                background-color: $blue;
            }
        }

        &__validate{
            margin-top: 5rem;
            padding: 0.5rem 1rem;
            opacity: 1;
            transition: 0.5s;

            &__close{
                opacity: 0;
                transition: 0.5s;
                cursor:default;
            }
        }
    }

    &__intro {
        font-size: 2rem;
        text-align:center;
    }

    &__options {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
        width: 100%;
        margin-top: 5rem;
        align-items: center;
    }

    &__themes {
        display: grid;
        width: 100%;
        grid-template-columns: 0.5fr 3fr 0.5fr;

        &__container {
            margin-top: 5rem;
            width: 100%;
            background-color: rgba(255,255,255, 0.75);
            border-radius: 1rem;
        }

        &__text {
            height: 100%;
            display: flex;
            //justify-content: center;
            align-items: center;
            transition: 0.5s; 
            color: $darkGrey;
            text-align: justify;

            &__animated {
                color: $darkBlue;
                font-weight: 800;
                font-size: 1.5rem;
                transition: 0.5s;
            }
        }

        &__last {
            padding-bottom: 1rem;
        }
    }
}