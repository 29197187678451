.profil {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    &__button {
        &__container {
            width: 80%;
            display: flex;
            justify-content: space-between;
            align-self: center;
            margin-top: 1rem;
            margin-bottom: 7vh;
        }
    }
    &__card {
        display: grid;
        padding: 1rem 2rem;
        position: relative;
        border-top: solid 1px rgb(11,11,11);
        border-radius: 1rem;
        width: 70%;
        justify-self: center;
        box-shadow: -1px 5px 10px 2px rgba(11,11,11,0.5);
        background-color: white;
        align-self: center;
    }
    &__container {
        display: flex;
        flex-direction: column;
    }
    &__title {
        text-align: center;
        font-size: 2rem;
        margin: 1.5rem 0;
        align-self: center;
    }
}

.reco {
    align-self:center;
    padding: 1rem 2rem;
    position: relative;
    border-top: solid 1px rgb(11,11,11);
    border-radius: 1rem;
    width: 70%;
    box-shadow: -1px 5px 10px 2px rgba(11,11,11,0.5);
    background-color: white;
    margin: 2rem 0;

    &__question{
        font-style: italic;
        font-size: 0.75rem;
    }

    &__title {
        text-align: center;
        font-size: 2rem;
        margin: 1.5rem 0;
        font-weight: 400;
    }

    &__text {
        margin: 1rem 0;
    }
}