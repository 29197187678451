@import '../../../vars';

.question{
    &__card{
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 80%;
        justify-self: center;

        &__button {
            background-color: $darkOrange;
            border: none;
            border-radius: 0.5rem;
            color: white;
            padding: 0.5rem 1rem;
            margin-left: 1rem;
        }

        &__part {
            flex: 1;
            display: grid;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }

        &__select{
            width: 100%;
            justify-self: center;
            padding: 0.25rem 0.5rem;
            border-radius: 0.5rem;

            &__container{
                display: flex;
                justify-content: space-between;
                width: 100%;
            }
        }
    }

    &__download {
        width: 100%;
        display: flex;
        
        &__img {
            width: 7.5vw;
            align-self: center;
            justify-self: center;
        }
    }

    &__next{
        width: 50px;
        height: 20px;
        background-color: $darkOrange;
        clip-path: polygon(0% 20%, 60% 20%, 60% 0%, 100% 50%, 60% 100%, 60% 80%, 0% 80%);
        
        &__container {
            display: flex;
            justify-content: space-between;
            margin-top: 1rem;
        }

        &__hidden {
            opacity:0;
        }

        &__reverse{
            transform: rotateY(180deg);
        }
    }
}