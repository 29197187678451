@import '../../vars';

.menu {
    height: 100vh;
    width: 20vw;
    display: flex;
    position: absolute;
    left: 0;
    flex-direction: column;
    justify-content: center;

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 90%;
    }

    &__ul {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        background: rgba(255,255,255,0.8);
        border-radius: 0 1rem 1rem 0;
        box-shadow: 3px 0px 15px 0px rgba($darkBlue, 0.5);
        padding: 1rem;
        margin: 1rem 0;
        height: 100%;
        width: 70%;
        font-size: clamp(1rem, 0.9vw, 0.9vh);

        &__undermenu {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        height: 100%;
        width: 100%;
        font-size: clamp(1rem, 0.9vw, 0.9vh);
        }
        
        
        &__li {
            max-width: 100%;
            transition: 0.5s;
            font-weight: 800;
            color: $darkBlue;

            &__undermenu{
            width: 90%;
            font-weight: 600;
            transition: 0.5s;
            color: $darkBlue;

            &__text{
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis !important;
            }
            }

        }
    }

    &__logo {
        width: 75%;

    }
}


.selected {
    transition: 0.5s;
    transform: translate(10%);
    color: $darkOrange;
}

.return {
    &__arrow {
        width: 50px; height: 50px;
        z-index: 11;
        background-color: $darkOrange;
        clip-path: polygon(60% 30%, 40% 45%, 100% 45%, 100% 55%, 40% 55%, 60% 70%, 0% 50%);
    }

    &__cursor{
        cursor: pointer;
        padding: 0.5rem;
    }
}