@import '../../vars';

.mobile{
    &__header {
        width: 80%;
        display: flex;
        align-self: center;
        justify-content: space-between;
        margin: 1rem 0;
    }

    &__logo {
        width: 50%;
        height: auto;
    }

    &__page {
        width: 100vw; height:100vh;
        position: relative;
        display: flex;
        flex-direction: column;

        &__overflow{
            overflow:hidden;
        }
        
        &__title{
            font-size: clamp(2rem, 2vh, 2vh);
            text-align: center;
        }
        
        &__question{
            &__container{
                display: grid;
                grid-template-columns: 1fr;
                height: 100%;
            }
            &__flex{
                display:flex;
                flex-direction: column;
            }

            &__sousquestion {
                display: grid;
                padding: 1rem 2rem;
                position: relative;
                border-top: solid 1px rgb(11,11,11);
                border-radius: 1rem;
                width: 70%;
                justify-self: center;
                box-shadow: -1px 5px 10px 2px rgba(11,11,11,0.5);
                background-color: white;

                &__container {
                    display: grid;
                }
            }

            &__title {
                text-align: center;
                font-weight: 500;
            }
        }
    }

    &__send {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 80%;
        align-self: center;
        justify-self: center;
        margin-top: 1.5rem;
        margin-bottom: 5vh;

        &__button {
        background-color: $darkOrange;
        border: none;
        border-radius: 0.5rem;
        color: white;
        padding: 0.25rem 0.5rem;
        margin-top: 0.5rem;
        width: 40%;
        }
    }
}