@import '../../vars';


.App {
  height: 100vh;
  display: flex;
  flex-direction: row;
  position: relative;
  overflow: hidden;
}

.language {
  width: 10vw;
  min-width: 150px;
  transition: 0.5s;
  font-weight: 800;
  padding: 0.25vh;
  z-index: -1;

  &:hover {
    cursor: pointer;
    color: $darkOrange;
    transition: 0.5s
  }

  &__button {
    background-color: $darkOrange;
    border: none;
    border-radius: 0.5rem;
    padding: 0.5vh 0.5vw;
    color: white;
    cursor: pointer;
    transition: 0.5s;
    min-width: 3rem;
    font-size: clamp(0.5rem, 1.75vh,1.75vh);
    &:hover {
      transition: 0.5s;
      background-color: $orange;
    }

    &__inactive{
      background-color: $darkGrey;
      color: $grey;

      &:hover {
        background-color: $darkGrey;
        cursor: not-allowed;
      }
    }
  }
  &__div {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    width: 100vw;
    left: 0; bottom: 0;
    height: 5vh;
    background-color: $darkBlue;
    color: white;
    border: none;
    z-index: 10;

    &__part {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 1
    }

    &__moitie {
      width: 40%;
      display: flex;
      z-index: 1;
    }
  }

  &__container {
    display: flex;
    position: absolute;
    flex-direction: column;
    text-align: center;
    background-color: $darkBlue;
    justify-content: space-between;
    align-items: center;
    top: -110%;
    transition: 0.5s;
    padding-bottom: 1rem;
    border-radius: 1rem 1rem 0 0;
    z-index: -101;
  }

  &__close {
    transition: 0.5s;
    transform: translateY(100%);
    opacity: 0;
    z-index: -101;
  }

  &__text {
    font-size: 1.75vh;
    font-weight: 700;
    cursor: pointer;
  }
}

.popup {
  &__save {
    &__response {
      position: absolute;
      transform: translateY(-85%);
      padding: 1rem;
      border-radius: 1rem;
      font-size: 1.5vh;
      font-weight: 600;
      transition: 0.5s;
      &__okay{
        background-color: $darkBlue;
      }
      &__bad {
        background-color: $darkOrange;
      }

      &__hiden {
        transition: 0.5s;
        opacity: 0;
        transform: translateY(0);
        z-index: -1;
      }

    }
  }
}

.footer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: clamp(0.75rem, 1.0vh, 1.0vh);
  text-align: center;
  line-height: 0.75rem;
  font-style: italic;
  transition: 0.5s;
  color: white;
  &:hover {
    color:$orange;
    transition: 0.5s;
  }

  &__company {
    font-style: normal;
    font-size: 1.5rem;
    letter-spacing: 4px;
  }
}

.notification-success {
  background-color: #205b76 !important;
  border-radius: 1rem !important;
}

.notification-warning {
  background-color: #e45424 !important;
  border-radius: 1rem !important;
}

.question__needAnswer {
  color: red;
}