@import '../../vars';

.home {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: -150px;
    &__button {
            margin-top: 5rem;
            padding: 1rem 2rem;
            border-radius: 1rem;
            font-size: 1.2rem;
            border: none;
            height: 4rem;
            color: white;
            font-weight: 800;
            background: $darkBlue;
            transition: 0.5s;
            cursor: pointer;

            &__close {
                height: 4rem;
                background-color: transparent;
                border: none;
                margin-top: 5rem;
                font-weight: 800;
                transition: 0.5s;
                color: transparent;
            }

            &:hover {
                background-color: $darkOrange;
                transition: 0.5s;
            }

            &__container {
                display: flex;
                flex-direction: row;
                width: 100%;
                justify-content: space-around;
            }

            &__change {
                margin-top: 5rem;
            padding: 1rem 2rem;
            border-radius: 1rem;
            font-size: 1.2rem;
            width: auto;
            border: none;
            color: white;
            font-weight: 800;
            background: $darkBlue;
            transition: 0.5s;
            cursor: pointer;

            &:hover {
                background-color: $darkOrange;
                transition: 0.5s;
            }

            }
    }
    &__loginText {
        text-align: center;
        font-size: 1.7rem;
        font-weight: 600;
    }
    &__mail {

        &__alert{
            color: red;
            margin-top: 1rem;
            font-weight: 700;
            position: absolute;
        }
        &__container {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
        }

        &__input {
            width: 20vw;
            height: 2.5vh;
            font-size: 2vh;
            padding-left: 1vw;
            border-radius: 0.5rem;
        }

        &__title {
            font-size: 3vh
        }
    }
    &__modal {
        z-index: 1000;
    }
    &__text {
        width: 70%;
        font-size: 2vh;
        margin-top: 5rem;
    }
    &__title{
        text-align: center;
        font-size: 2.5vh;
        padding: 2rem 5rem;
        background-color: $darkBlue;
        clip-path: polygon(5% 0%, 100% 0%, 95% 100%, 0% 100%);
        color: white;
        font-weight: 800;
    }
}

.pin {
    width : 50px;
    height: 100px;
    border-radius: 1rem;
    margin: 1rem 1rem 0 0;
    font-size: 95px;
    text-align: center;
    background-color: white;
    color: $darkGrey;
    &__closeButton {
        clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
        background-color: $darkOrange;
        width: 75px; height: 75px;
        position: absolute;
        top: 2rem;
        right: 2rem;
        cursor: pointer;
        border-radius: 100%;
    }
    &__container {
        color: white;
        font-size: 2rem;
        text-align: center;
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%, -50%);
        
        
        &__onlypin {
            color: $darkBlue; 
            display: flex;
            flex-direction: row;
            justify-content: center;
        }

        &__second{
            position: relative;
            transform: none;
            top: 0; left: 0;
            align-self: center;
        }
    }

    &__error {
        &__text {
            color: rgb(255, 33, 33);
            font-size: 0.75rem;
        }
    }

    &__forgot {

        &__link{
            font-size: 1.2rem !important;
            font-weight: 600;
            cursor: pointer;
        }
        &__text{
            font-size: 1rem;
        }
    }
    &__modal{
        position: absolute;
        width: 100vw; height: 100vh;
        top: 0; left: 0;
        background-color: rgba($darkGrey, 0.95);
        z-index: 10;
    }

    &__personnalisation {
        color: white;
        position: absolute;
        top: 50%; left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        font-size: 2rem;
        text-align: center;
        display: flex; 
        flex-direction: column;
    }
    
    &__reject {
    border: 5px solid red;
}
}