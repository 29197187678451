@import '../../vars';

.modalPin {
    &__background {
        width: 100vw;
        height: 100vh;
        background-color: rgba(black, 0.8);
        position: absolute;
        top: 0; left:0
    }
    &__container {
        position: absolute;
        top: 50%; left: 50%; transform: translate(-50%, -50%);
        background-color: white;
        border-radius: 1rem;
        padding: 1rem;
        width: 60%;
    }

    &__title {
        font-size: 1.5rem;
        text-align: center; 
    }
}

.mobilePin {
    width: 55%;
    height: 10vh;
    border: solid 2px $darkOrange;
    border-radius: 0.5rem;
    font-size: 10vh;

    &__button {
        margin-top: 2.5rem;
        padding: 0.75rem 1.5rem;
        border-radius: 0.5rem;
        font-size: 0.8rem;
        border: none;
        color: white;
        font-weight: 800;
        background: $darkBlue;
    }
    
    &__container {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 1rem;
        margin-top: 2rem;
        
    }

    &__error {
        font-size: 0.8rem;
        text-align: center;
        color: $darkOrange;
        margin-top: 1rem;

        &__link {
            font-weight: 800;
            margin-top: 0.5rem;
            color: red;
        }
    }

    &__input {
        width: 80%;
        height: 2.5vh;
        font-size: 2vh;
        padding-left: 1vw;
        border-radius: 0.5rem;
        align-self: center;
        margin-top: 2rem;
    }

    &__newPin {
        display: flex;
        flex-direction: column;
        
    }

    &__reject{
        border: solid 2px red;
    }
}