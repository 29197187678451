@import '../../vars';

@keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes fade-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
.questions {
    width: 100%;
    display: flex;
    margin: 5rem 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &__card {
        position: relative;
        display: flex;
        flex-direction: column;
        min-height: 80px;
        top:0;
        justify-content: center;
        border-top: solid 1px rgb(11,11,11);
        padding: 1rem 2rem;
        margin-top: 5rem;
        border-radius: 1rem;
        box-shadow: -1px 5px 10px 2px rgba(11,11,11,0.5);
        z-index:1;
        background-color: white;
        flex: 2;


        &__answer {
            position: relative;
            border-top: solid 1px rgb(11,11,11);
            padding: 1rem 2rem;
            margin: 5rem;
            border-radius: 1rem;
            box-shadow: -1px 5px 10px 2px rgba(11,11,11,0.5);
            z-index:1;
            background-color: white;
            transition: 0.5s;
            margin-top: -2rem;
            cursor: pointer;

            &:hover {
                transition: 0.5s;
                background-color: $darkBlue;
                color: white;
            }
        }

        &__button {
            background-color: $orange;
            color: $darkGrey;
            padding: 0.5rem 0.5rem;
            border: none;
            height: auto;
            width: auto;
            min-width: 70%;
            border-radius: 0.5rem;
            font-size: 1rem;
            transition: 0.5s;
            margin: 0.5rem 0.5rem;
            text-align: center;
            cursor: pointer;
            &:hover {
                transition: 0.5s;
                background-color: $darkBlue;
                color: white;
            }

            &__close {
                background-color: none;
                color: transparent;
                cursor: none;
                margin: 0.5rem 0.5rem;
                padding: 0.5rem 1rem;
                min-height: 1rem;
            }
            &__container {
                flex: 1;
                width: 90%;
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                margin-left: 1rem;

                &__bis {
                    justify-content: space-around;
                }
            }
            
        }

        &__container {
            display: flex;
            width: 100%;
            justify-content: space-between;
            margin-top: 5rem;
        }

        &__feedback {
            width: 80%;
            align-self: center;
        }

        &__error {
            color: red;
            text-align: center;
            font-size: clamp(1.5rem, 2vh, 2vh)
        }

        &__global {
            justify-self: center;
            width: 80%;
            height:100%;
        }

        &__part {
            display: grid;
            grid-template-columns: 2fr 1fr;
            position: relative;

            &__global {
                display: flex;
                flex: 1;
                align-items: center;
            }
        }

        &__response {
            width: 100%;
            display: flex;
            position: relative;
            align-items: center;
            justify-content: center;
        }

        &__select {
            width: 100%;

            &__control{
                height: 2rem;
                flex: 1 1;
                padding-left: 0.5rem;
                border-radius: 0.25rem;
                resize: none;
                width: 100%;
                border: 1px solid black;
                z-index: 0;
                min-height: 0 !important;
            }

            &__value-container {
                font-size: 0.8rem;
            }

            &__option {
                transition: 0.5s;
                padding-left: 0.25rem;
                &:hover {
                    background-color: $orange;
                    transition: 0.5s;
                }
            }

            &__menu {
                background-color: white;
                border-radius: 0.25rem;
                width: 100%;
                border: 1px solid black;
            }

            &__indicator{
                &s{
                    display: flex;
                    justify-content: center;
                }
                &-separator {
                    background-color: black;
                    align-self: center !important;
                    height: 80%;
                    margin-right: 0.5rem;
            }
        }
        }

        &__slide {
            flex: 1;
        }

        &__supp {
            width: 25px;
            height: 25px;
            border: none;
            cursor: pointer;
            margin-left: 2rem;
            background-color: red;
            clip-path: polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%);
        }
        
        &__text {
            position: relative;
            display: flex;
            text-align: justify;
            width: 90%;
            &__informations {
                font-size: 0.75rem;
                width: 15px; height:15px;
                font-weight: 700;
                color: white;
                text-align: center;
                margin-left: 0.25rem;
                border-radius: 100%;
                background-color: $darkGrey;
            }

            &__wide {
                text-align: justify;
            }
        }

        &__input {
            height: 1.25rem;
            flex:1;
            padding-left: 0.5rem;
            border-radius: 0.25rem;
            resize: none;
            width: 100%;
            border: 1px solid black;
            z-index: 0;
        }

        &__textarea {
            flex:1;
            padding: 0.5rem;
            border-radius: 0.25rem;
            resize: vertical;
            width: 100%;
            min-height: 2rem;
            border: 1px solid black;
            z-index: 0;
            font-family: Arial, Helvetica, sans-serif;

            &__container{
                display: flex;
                justify-content: space-between;
                flex:1;
                flex-wrap:wrap;
            }
        }

        &__title {
            font-size: 1.25rem;
        }

        &__wide {
            width: 94%;
            display: flex;
            justify-self: center;
            position: relative;
            align-items: center;
            justify-content: center;
            flex-direction: column;
        }
    }
    &__endingMessage{
        text-align: center;
        margin-top: 5rem;
        font-size: 1.5rem;
        color: $darkOrange;
    }
    &__img {
            position: absolute;
            width: 50px;
            top:0; left: 0;
            z-index: 0;
            border-radius: 1rem;
        }
    
    &__radar {
        width: 100%; height: 100%;
    }

    &__swipes {
        clip-path: polygon(0% 20%, 60% 20%, 60% 0%, 100% 50%, 60% 100%, 60% 80%, 0% 80%);
        background-color: $darkOrange;
        width:5vh;
        height: 5vh;
        position: absolute;
        top: 50%; right: 20px;
        transition: 0.5s;
        z-index: 10;

        &:hover {
            background-color: $darkBlue;
            transition: 0.5s;
        }
    }
    
    &__text {
    font-size: 1.2rem;
    position: inherit;
    z-index: 1;
    }
    
    &__title {
        position: inherit;
        font-size: 1.5rem;
        font-weight: 600;
        z-index: 1;

        &__page {
            text-align: center;
            font-size: 2rem;
            color: $darkOrange;
            font-weight: 800;
        }
    } 
}

.reverse {
    top:50%; left: 18vw;
    transform: rotate(180deg);
}

.question__active {
    animation: fade-in 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.question__inactive {
    display: none;
}


.other {
    &__big {
        flex: 2;
    }
    &__choice {
        background-color: $darkBlue;
        color: white;
        font-weight: 700 ;
        border: 2px solid $darkGrey;

        &:hover {
            background-color: $blue;
        }
    }
    &__little{
        flex: 1;
    }

    &__marginleft {
        margin-left: 2rem;
    }

}

.slider{
    &__container {
        flex: 1;
        display: flex;
        align-items: center;
    }
    &__text{
        margin: 0 0.75vw;
    }
}
#error {
    color: red;
}

.validate {
    &__button{
        position: absolute;
        top:4vh; right: 1vw;
        width: 8vw; min-width: 150px;
        font-size: 1.5vh;
        height: auto;
        padding: 0.25vh 1vw;
        z-index: 5
    }
}

.infobulle {
    font-size: 1.2vh;
    position: relative;
    z-index: 5;
    top: -1rem;
    max-width: 100%;
    max-height: 40%;
    overflow: auto;
    background-color: white;
    color: $darkBlue;
    padding: 0.25rem 0.5rem;
    border-radius: 1rem;
    border: 0.1rem solid $darkBlue;
}

.endding {
    &__button {
        margin-top: 5vh;
    }

    &__container {
        display: flex; 
        justify-content: flex-end;
    }
}

.icone {
    width: auto;
    height: clamp(2rem, 2.5vh, 2.5vh);
    transform: translateY(-25%);
    margin-left: clamp(1rem, 0.5vw, 0.5vw);
    cursor: pointer;
}

.file {
    display: flex;
    max-width: 25%;
    &__name{
        font-size: clamp(0.8rem, 0.8vh, 0.8vh);
        white-space: nowrap;
        overflow-x: hidden;
    }
}