$darkBlue: rgb(0,129,183);
$blue: rgb(0,174,217);
$middleBlue: rgb(77, 219, 255);
$darkGrey: rgb(88,88,90);
$grey: rgb(135,136,138);
$middleGrey: rgb(175,175,175);
$darkOrange: rgb(218,91,21);
$orange: rgb(244,157,0);
$middleOrange: rgb(254, 204, 109);

 @keyframes scale-in-center {
    0% {
        transform: scale(0);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
  }
  
  